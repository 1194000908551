<script>
import {defineComponent} from 'vue'
import skeleton from "@/components/custom/skeleton.vue";
import Layout from "@/layouts/main.vue";
import axios from "axios";
import store from "@/state/store";

export default defineComponent({
  name: "Details",
  components: {Layout, skeleton},
  data() {
    return {
      train: {},
      tabs: [
        {
          value: 'train_details_main',
          label: 'Main',
        },
        {
          value: 'train_details_smgs',
          label: 'SMGS',
        },
        {
          value: 'train_details_companies',
          label: 'Companies & Users',
        },
        {
          value: 'train_details_dislocation',
          label: 'Dislocations',
        },
        {
          value: 'train_details_wagon_upload',
          label: 'Wagon Upload',
        },
        {
          value: 'train_details_settings',
          label: 'Settings',
        },
      ]
    }
  },
  computed: {
    allowed_tabs() {
      return this.tabs.filter(tab => store.state.user.pages.includes(tab.value))
    }
  },
  methods: {
    async getTrainBySlug() {
      try {
        let response = await axios.get(`/train/${this.$route.params.train_slug}/`)
        this.train = response.data
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    await this.getTrainBySlug()
  }
})
</script>

<template>
  <Layout>
    <b-row>
      <b-col lg="12">
        <b-card no-body class="mt-n4 mx-n4">
          <div class="bg-soft-light">
            <b-card-body class="pb-0 px-4">
              <b-row class="mb-3">
                <b-col md>
                  <b-row class="align-items-center g-3">
                    <b-col md="auto">
                      <div class="avatar-md">
                        <div class="avatar-title bg-light rounded-circle">
                          <i class="bx bx-train text-dark h1 mb-0"></i>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="auto">
                      <div v-if="Object.keys(train).length > 0">
                        <h4 class="fw-bold">{{ train.name }}</h4>
                        <div class="hstack gap-3 flex-wrap">
                          <div>
                            <span>ETD : </span>
                            <span class="fw-medium"> {{ train.departure_date }} </span>
                          </div>
                          <div class="vr"></div>
                          <div>
                            <span>ETA : </span>
                            <span class="fw-medium"> {{ train.expected_arrival_date }} </span>
                          </div>
                          <div class="vr"></div>
                          <b-badge variant="danger" class="bg-danger fs-12 text-uppercase"> {{ train.status }}
                          </b-badge>
                        </div>
                      </div>
                      <div v-else style="min-width: 250px">
                        <skeleton class_list=" bg-soft-info py-2 w-75 mb-3"/>
                        <div class="hstack gap-3 flex-wrap">
                          <div class="w-100">
                            <skeleton class_list=" bg-soft-info py-1"/>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <ul class="nav nav-tabs-custom border-bottom-0">
                <li class="nav-item" v-for="tab in allowed_tabs" :key="`tab_${tab.value}`">
                  <router-link :to="{name: tab.value}"
                               class="nav-link fw-semibold"
                               :class="{'active': $route.name === tab.value}"
                  >
                    {{ tab.label }}
                  </router-link>
                </li>
              </ul>
            </b-card-body>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="tab-content text-muted" v-if="Object.keys(train).length > 0">
          <router-view/>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<style scoped>

</style>